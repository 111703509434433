import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from "js-cookie";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breakthrouth_userinfo: JSON.parse(sessionStorage.getItem('breakthrouth_userinfo'))||{},
  },
  mutations: {
    setuserInfo (state, data) {
      this.state.breakthrouth_userinfo = JSON.parse(data)
      Cookies.set('breakthrouth_userinfo',JSON.parse(data), { expires: 30 });
    },
  },
  actions: {
  },
  modules: {
  }
})

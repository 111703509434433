export const MATHALL = [
  [
    {
      k: '\\frac{b}{a}',
      v: '\\frac{}{}',
    },
    {
      k: 'a\\frac{c}{b}',
      v: '{}\\frac{}{}',
    },
    {
      k: '+',
      v: '+'
    },
    {
      k: '-',
      v: '-'
    },
    {
      k: '\\times',
      v: '\\times'
    },
    {
      k: '{\\div}',
      v: '{\\div}'
    },
    {
      k: '=',
      v: '='
    },
    {
      k: '\\neq',
      v: '\\neq',
    },
    {
      k: '>',
      v: '>'
    },
    {
      k: '<',
      v: '<'
    }
  ],
  [
    {
      k: '\\geq',
      v: '\\geq ',
    },
    {
      k: '\\leq',
      v: '\\leq ',
    },
    {
      k: '\\pi',
      v: '\\pi',
    },
    {
      k: '^\\circ度',
      v: '^\\circ',
    },
    {
      k: '\\sim',
      v: '\\sim'
    },
    {
      k: '\\triangle',
      v: '\\triangle'
    },
    {
      k: '\\angle',
      v: '\\angle'
    },
    {
      k: '//',
      v: '//'
    },
    {
      k: '\\perp',
      v: '\\perp'
    },
    {
      k: '\\%',
      v: '\\%'
    }
  ]
]
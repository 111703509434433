<template>
  <div class="height100vh loginmain">
    <h2>您好！</h2>
    <h5>请填写您的信息</h5>
    <van-form  class="login-form" ref="loginForm">
      <h3>学员信息</h3>
      <!-- 就读年级 -->
      <van-field name="grade"
                  :value="gradetext"
                  placeholder="就读年级"
                  @focus="forbid"
                  right-icon="arrow-down"
                  :rules="[{ required: true, message: '请选择就读年级' }]"
                  @click="showPicker('grade')">
      </van-field>
      <van-popup v-model="gradeshowPicker"
                  position="bottom">
        <van-picker show-toolbar
                    :columns="gradelist"
                    value-key="title"
                    @confirm="onConfirmGrade($event,'grade')"
                    @cancel="gradeshowPicker = false" />
      </van-popup>
      <van-field v-model="loginForm.id_card"
                name="card"
                placeholder="学员身份证号码"
               :rules="ruleCard">
      </van-field>
      <!-- 有无过敏史 -->
      <van-field name="type"
                  :value="typetext"
                  placeholder="有无过敏史"
                  @focus="forbid"
                  right-icon="arrow-down"
                  :rules="[{ required: true, message: '请选择有无过敏史' }]"
                  @click="showPicker('type')">
      </van-field>
      <van-popup v-model="typeshowPicker"
                  position="bottom">
        <van-picker show-toolbar
                    :columns="typelist"
                    value-key="title"
                    @confirm="onConfirmGrade($event,'type')"
                    @cancel="typeshowPicker = false" />
      </van-popup>
      <div class="btngroup">
        <van-button block type="info" color="#4D78FF" :loading="isLoading" @click="onSubmit">提交信息</van-button>
      </div>
    </van-form> 
  </div>
</template>

<script>
import { studentDetail } from "api";
import { GRADE_LIST,TYPE_LIST } from '../utils/config'
export default {
  name:'stuInfo',
  data() {
    return {
      query:{},
      isLoading: false,
      loginForm:{
        id_card:'',
        grade_id:'',
        type:'',
        token:JSON.parse(this.$cookie.get('breakthrouth_userinfo')).token
      },
      gradetext:'',
      gradelist: GRADE_LIST,
      gradeshowPicker: false,
      typetext:'',
      typelist: TYPE_LIST,
      typeshowPicker: false,     
      ruleCard: [
        {
          required: true,
          message: "身份证号码不能为空",
          trigger: "blur",
        },
        { pattern: /^[0-9a-zA-z]{6,16}$/, message: "长度在6-16个字符" },
      ],
    }
  },
  mounted () {
    window.history.pushState(null, null, document.URL);
    // 给window添加一个popstate事件，拦截返回键，执行this.onBrowserBack事件，addEventListener需要指向一个方法
    window.addEventListener("popstate", this.onBrowserBack, false);
  },
  beforeDestroy () {
    window.removeEventListener("popstate", this.onBrowserBack, false);
  },
  destroyed () {
    window.removeEventListener("popstate", this.onBrowserBack, false);
  },
  created(){
    this.query = this.$route.query
  },
  methods: {
    onBrowserBack () {
          window.location.reload()
      },
    forbid () {
      //禁止软键盘弹出
      document.activeElement.blur();
    },
    showPicker(type){
      this[type+'showPicker'] = true;
    },
    onConfirmGrade (e,tp) {
      if(tp=='grade'){
        this.loginForm.grade_id = e.id;
      }else{
        this.loginForm.type = e.id;
      }
      this[tp+'text']=e.title;
      this[tp+'showPicker'] = false;
    },
    onConfirmAllergy (e) {
      this.allergy = e;
      this.allergyshowPicker = false
    },
    /**提交 */
    onSubmit() {
        this.$refs.loginForm.validate().then(async (valid)=>{
          this.isLoading = true;
          let res = await studentDetail(this.loginForm);
          this.isLoading = false;
          if (res.code == 0) {
            this.$toast(res.msg)
            setTimeout(()=>{
              this.$router.push({
                path:"/index",
                query:{
                  activity_id:this.query.activity_id,
                }
              });
            },2000)
          }
        }).catch(()=>{
          this.$toast('缺少必填信息！')
        });    

    },
  },
}
</script>

<style lang="scss" scoped>
.loginmain{
  background: url(../assets/imgs/bg.png) no-repeat;
  background-size: cover;
  padding:4rem 1rem 0;
  box-sizing: border-box;
  text-align: left;
  h2{
    color: #fff;
    font-size: 1.5rem;
  }
  h3{
    color: #1C1C1C;
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  h5{
    color: #fff;
    font-size: 1.1rem;
    margin: 1.1rem 0;
    font-weight: normal;
  }
  .login-form{
    background: #fff;
    border-radius: 10px;
    padding: 2rem 1.2rem;
  }
}
</style>
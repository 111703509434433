<template>
    <div class="analysis">
        <img src="../assets/imgs/guanbi_icon.png" alt="" class="guanbi" @click="backindex">
        <img src="../assets/imgs/report.png" alt="" width="100%">
        <p class="congt"><span  class="congttext">恭喜通关</span> </p>
        <div class="analysisbox">
            <p>通关正确率<span class="gq">（全部关卡）</span></p>
            <div v-for="(item,idx) in analysisdata.list" :key="idx" class="analysislist"> 
                <p class="sort">第{{customoptions[item.sort]}}关卡</p>
                <p class="rate">
                    <span :style="{ width: item.rate *0.8 +'%' }"></span>
                    <span>{{ item.rate }} %</span>
                </p>
            </div>
        </div>
        <div class="btngroup">
            <van-button block type="primary" class="whitebtn" round  @click="backindex">返回</van-button>
        </div>
    </div>
</template>
<script>
import { reportAnalysis } from 'api';
import { CUSTOM_OPTIONS } from 'utils/config'
export default {
    data() {
        return {
            analysisdata:{},
            token:'',
            query:{},
            customoptions:CUSTOM_OPTIONS
        }
    },
    created () {
        this.query = this.$route.query;
        this.token = JSON.parse(this.$cookie.get('breakthrouth_userinfo')).token
        this.report()
    },
    methods:{
        backindex(){
            this.$router.push({
                path:'./index',
                query:{
                  activity_id:this.query.activity_id,
                }
            })
        },
        async report() {
            this.$toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
            let {code, data} = await reportAnalysis({token:this.token,activity_id:this.query.activity_id} )
            this.$toast.clear()
            if (code === 0) {
                this.analysisdata= data
            }
    },
    }
}
</script>
<style lang="scss" scoped>
.analysis{
    min-height: 100vh;
    background: url(../assets/imgs/bg.png) no-repeat;
    background-size: cover;
    padding:0 1rem 1rem;
    box-sizing: border-box;
    color: #fff;
    position: relative;
    .guanbi{
        position: absolute;
        left: 1rem;
        top:1rem;
        width: 2.75rem;
        height: 2.75rem;
    }
    .congt{
        font-weight: 600;
        color: #FFFFFF;
        font-size: 1.4rem;
        margin-top: -3rem;
        .congttext{
            position: relative;
            &::before,&::after{
            content: '';
            position: absolute;
            width: 38px;
            height: 4px;
            background: #FFFFFF;
            top:50%;
            transform: translateY(-50%);
            }
            &::before{
                left:-45px;
            }
            &::after{
                right:-45px;
            }
        }


    }
    .analysisbox{
       background: rgba($color: #fff, $alpha: .22);
       padding: 2rem 1rem;
       border-radius: 17px;
       margin: 2rem auto 3rem;
       font-size: 1rem;
       .gq{
        font-size: .8rem;
       }
        .analysislist{
            display: flex;
            margin-top: 2.5rem;
            .sort{
                flex: 1;
                text-align: left;
            }
            .rate{
                flex: 3;
                text-align: left;
                span:first-child{
                    height: 8px;
                    background: #FFFFFF;
                    border-radius: 6px;
                    display: inline-block;
                    margin-right: 10px;
                }
                span:last-child{
                    font-size: .8rem;
                }
            }
        }
    }
    .whitebtn{
        background: #fff;
        color: #567EFF;
        border-color: rgb(255, 255, 255);
        margin: 0;
    }
}
</style>
<template>
  <div class="height100vh result">
    <Nav title="结果"></Nav>
    <div class="section" >
        <!-- 活动当前关卡通过，存在下一关 -->
      <div v-show="resultdata.next_report_id !== 0 && resultdata.pass_report_num!=0 &&  (resultdata.pass_report_num<resultdata.total_report_num)">
        <p >恭喜你！你通过了第{{customoptions[resultdata.pass_report_num ] }}关的测试</p>
        <p >可以开始进行下一关的测试了</p>
      </div>
      <!-- 活动当前关卡未通过 -->
      <p v-show="resultdata.next_report_id == 0 && resultdata.pass_report_num == 0">很遗憾！您未通过当前关卡！</p>
        <!-- 活动当前关卡通过 -->
      <p v-show="resultdata.next_report_id == 0 && (resultdata.pass_report_num == resultdata.total_report_num)">恭喜你闯过了所有的关卡～</p>
        <!-- 活动当前关卡未通过，多关 -->
      <p v-show="resultdata.next_report_id == 0 && resultdata.pass_report_num!=0 && (resultdata.pass_report_num <resultdata.total_report_num)">你已经闯过{{ customoptions[resultdata.pass_report_num ]}}个关卡，请下次再接再厉！</p>
      <div class="btngroup" v-if="resultdata.next_report_id !== 0 && resultdata.pass_report_num!=0 &&  resultdata.pass_report_num<resultdata.total_report_num">
        <van-button block type="primary" class="plainbtn" round  @click="lookbtn('answer')" >查看答案</van-button>
        <van-button block type="primary" round color="#4D78FF" @click="handleNextPaper" >下一关</van-button>
      </div> 
      <div class="btngroup" v-else>
        <van-button block type="primary" class="plainbtn"  round  @click="lookbtn('answer')" >查看答案</van-button>
        <van-button block type="primary" round color="#4D78FF" @click="lookbtn('report')">查看报告</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from 'comps/Nav.vue'
import { CUSTOM_OPTIONS } from 'utils/config'
export default {
  name: 'Result',
  data() {
    return {
      query: {},
      resultdata:{},
      customoptions:CUSTOM_OPTIONS
    }
  },
  mounted () {
        window.history.pushState(null, null, document.URL);
    // 给window添加一个popstate事件，拦截返回键，执行this.onBrowserBack事件，addEventListener需要指向一个方法
        window.addEventListener("popstate", this.onBrowserBack, false);
  },
  beforeDestroy () {
      window.removeEventListener("popstate", this.onBrowserBack, false);
  },
  destroyed () {
      window.removeEventListener("popstate", this.onBrowserBack, false);
  },
  created () {
    this.$nextTick(()=>{
      this.query = this.$route.query
      this.resultdata= JSON.parse(sessionStorage.getItem('submitebackdata'))
    })

  },
  methods: {
    onBrowserBack () {
        window.location.reload()
    },
    //查看答案或报告
    lookbtn(type,activity_id){
        this.$router.push({  
            path:type=='answer'?'./answersheet':'./report',
            query:{
                activity_id:this.resultdata.activity_id,
            }
        })
    },
    handleNextPaper() {
      this.$router.push({
        path: '/answerquestion',
        query: {
          report_id: this.resultdata.next_report_id,
          activity_id:this.resultdata.activity_id,
        }
      })
    },

  },
  components: {
    Nav,
  },
}
</script>

<style lang="scss" scoped>
.result {
  .section {
    align-items: flex-start;
    padding:45% 1rem 0;
    .title {
      height: 1.88rem;
      line-height: 1.88rem;
      padding: 0 1.31rem;
      background: rgba(240,73,67,0.25);
      color: #F04943;
      font-size: 0.88rem;
      border-radius: 0.94rem;
    }
    .van-circle {
      position: relative;
      font-size: 0.81rem;
      margin: 1rem auto 0;
      & > span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #888;
        span {
          display: inline-block;
          margin-top: 0.56rem;
          color: #53C87E;
          font-size: 1.38rem;
        }
      }
    }
    p {
      font-size: 1rem;
      line-height: 30px;
      color: #92959B ;
      .light {
        color: #53C87E;
      }
    }
  }
    .btngroup{
      margin-top:9rem;
    }
  }
  .btns {
    justify-content: space-between;
    padding: 0 1rem;
    margin-top: 1.5rem;
    span {
      display: inline-block;
      width: 10rem;
      height: 2.75rem;
      line-height: 2.75rem;
      border-radius: 1.38rem;
      &:first-child {
        background: rgba(240,73,67,0.25);
        color: #F04943;
      }
      &:last-child {
        background: #F04943;
        color: white;
      }
    }
  }
  .card {
    background: white;
    margin-top: 1.5rem;
    border-radius: 1.38rem;
    padding-top: 1.57rem;
    .card-nav {
      justify-content: space-between;
      padding: 0 1rem;
      .card-left {
        align-items: center;
        img {
          width: 1.31rem;
        }
        span {
          margin-left: 0.56rem;
          font-size: 1rem;
          color: #000;
        }
      }
      .card-right {
        div {
          align-items: center;
          margin-left: 1.56rem;
          font-size: 0.81rem;
          color: black;
          &:first-child {
            margin-left: 0;
          }
          .icon {
            display: inline-block;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            margin-right: 0.31rem;
            &.right {
              background: #53C87E;
            }
            &.error {
              background: #D76156;
            }
          }
        }
      }
    }
    .card-bottom {
      flex-wrap: wrap;
      padding: 0 0.03rem 0.5rem;
      margin-top: 2.06rem;
      span {
        display: inline-block;
        width: 2.63rem;
        height: 2.63rem;
        line-height: 2.63rem;
        border-radius: 50%;
        background: #53C87E;
        margin: 0 0.97rem 1.44rem;
        color: white;
        &.error {
          background: #D76156;
        }
      }
    }
  }
  .cover {
    margin: 1.56rem 2.75rem 0;
    align-items: center;
    .icon-arrow {
      width: 3.88rem;
      align-self: flex-end;
    }
    div {
      width: 13.63rem;
      height: 9.6rem;
      background-image: url(../assets/imgs/guide_bg.png);
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 1.13rem;
      padding: 0 1.75rem;
      align-items: center;
      p {
        text-align: left;
        color: #e5e5e5;
        font-size: 1rem;
        line-height: 1.3;
      }
    }
    .know-btn {
      width: 6.88rem;
      margin-top: 1.63rem;
    }
  }

</style>
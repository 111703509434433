<template>
  <div class="answer-sheet">
    <Nav title="答题卡">
      <template #left>
        <img src="../assets/imgs/left_arrow.png" class="left-icon" alt="" @click="$router.go(-1)">
      </template>
    </Nav>

    <div class="card"  v-if="list.length">
      <div v-for="(pitem, pindex) in list" :key="pindex">
        <div class="fr card-nav" >
          <div class="fr card-left" v-if="list.length==1">
            <img src="../assets/imgs/list_icon.png" alt="">
            <span>答题卡</span>
          </div>
          <div class="fr card-left" v-else>
            <span class="listindex">{{pindex+1}}</span>
            <span>第{{ customoptions[pitem.sort] }}关</span>
          </div>
          <div class="fr card-right">
            <div class="fr">
              <span class="right icon"></span>
              <span>答对</span>
            </div>
            <div class="fr">
              <span class="error icon"></span>
              <span>答错</span>
            </div>
          </div>
        </div>
        <div class="fr card-bottom">
          <span 
            v-for="(item, index) in pitem.questions"
            :key="item.quesiton_id"
            :class="{'error': item.submit_result !== 1}"
            @click="detail(pindex,index)"
          >{{index+1}}</span>
        </div>
      </div>
      <div class="btngroup">
        <van-button block type="info" color="#4D78FF" @click="detail(0,0)">查看答案</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from 'comps/Nav'
import { reportResult } from 'api';
import { CUSTOM_OPTIONS } from 'utils/config'
export default {
  name: 'AnswerSheet',
  data() {
    return {
      query: {},
      token:'',
      list: [],
      customoptions:CUSTOM_OPTIONS
    }
  },
  components: {
    Nav,
  },
  computed: {
    // rightnum() {
    //   var rightnum=0;
    //   if(this.list.length == 1){
    //       rightnum = this.list[0].questions.filter(v=>v.submit_result==1).length
    //   }
    //   return  rightnum
    // },
  },
  created () {
    this.query = this.$route.query
    this.token = JSON.parse(this.$cookie.get('breakthrouth_userinfo')).token
    this.result()
  },
  methods: {
    // 查看解析
    async result() {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      let {code, data} = await reportResult({...this.query,token:this.token})
      this.$toast.clear()
      if (code === 0) {
        this.list = data.list || []
      }
    },
    detail(pindex=0,index = 0) {
      this.$router.push({
        path: './questionresult',
        query: {
          ...this.query,
          pindex,
          index,
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.answer-sheet {
  min-height: 100vh;
  background: #F4F7F9;

  .card {
    background: white;
    margin-top: 0.94rem;
    padding:1.69rem 0  4.5rem;
    border-radius: 0.625rem;
    .card-nav {
      justify-content: space-between;
      padding: 0 1rem;
      .card-left {
        align-items: center;
        img {
          width: 1.31rem;
        }
        span {
          margin-left: 0.56rem;
          font-size: 1rem;
          color: #000;
        }
        .listindex{
          width: 17px;
          height: 17px;
          line-height: 17px;
          color: #fff;
          font-size: 12px;
          background: #3EE372;
        }
      }
      .card-right {
        div {
          align-items: center;
          margin-left: 1.56rem;
          font-size: 0.81rem;
          color: black;
          &:first-child {
            margin-left: 0;
          }
          .icon {
            display: inline-block;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            margin-right: 0.31rem;
            &.right {
              background: #3EE372;
            }
            &.error {
              background: #FF9612;
            }
          }
        }
      }
    }
    .card-bottom {
      flex-wrap: wrap;
      padding: 0 0.03rem 0.5rem;
      margin-top: 2.06rem;
      span {
        display: inline-block;
        width: 2.63rem;
        height: 2.63rem;
        line-height: 2.63rem;
        border-radius: 50%;
        background: #3EE372;
        margin: 0 0.97rem 1.44rem;
        color: white;
        cursor: pointer;
        &.error {
          background: #FF9612;
        }
      }
    }
  }
  .btngroup{
    margin: 2rem 1rem 0;
  }
}
</style>
<template>
  <div class="answer-question" ref="mathjaxContainer">
    <div class="header fc">
      <Nav title="活动答案">
        <template #left>
          <img src="../assets/imgs/left_arrow.png" class="left-icon" @click="history">
        </template>
        <template #rightIcons>
          <div class="right-icons fr">
            <img src="../assets/imgs/answer_sheet.png" @click="history">
          </div>
        </template>
      </Nav>
      <!-- <div class="fr nav-bottom" v-if="list.length">
        <span>{{title}}</span>
        <div>
          <img src="../assets/imgs/right.png" alt="" v-if="questionObj.submit_result === 1">
          <img src="../assets/imgs/error.png" alt="" v-else>
          <b>{{activeIndex + 1}}</b>/{{list.length}}
        </div>
      </div> -->
    </div>
    <section class="fc" v-if="list.length">
      <p class="allcount"><span> 题数 {{activeIndex + 1}} </span> /{{list.length}}</p>
      <div style="display:flex; align-items: baseline;">
        <span class="question" v-html="questionObj.question" :style="styleObject"></span>
      </div>
      <div class="scroll" v-if="isShowScroll" :style="{marginLeft: left + '%', width: width + '%'}"></div>

      <div class="list fc" v-if="questionObj.question_type === 101">
        <div 
          class="fr item itemchoose"
          :class="{
            right: item.flag === 1,
            error: questionObj.submit_answer[0].submit_result === 0 && questionObj.submit_answer[0].option_id === item.option_id
          }"
          v-for="(item, index) in questionObj.content" 
          :key="item.option_id"
        >
          <span>{{options[index]}}</span>
          <span v-html="item.value" class="questionlist" :style="stylequestionlist(index)"></span>
          <div class="scroll" v-if="isShowScrollquestion[index]" ></div>
        </div>
      </div>
      <div class="list fc" v-else-if="questionObj.question_type === 200">
          <div 
            class="fr item"
            :class="{
              right:questionObj.content.value === '1',
              error: questionObj.content.value === '0' && questionObj.submit_answer[0].value == '1'
            }"
          >
            <span></span>
            <span>正确</span>
          </div>
          <div 
            class="fr item"
            :class="{
              right:questionObj.content.value === '0',
              error: questionObj.content.value === '1' && questionObj.submit_answer[0].value == '0'
            }"
          >
            <span></span>
            <span> 错误</span>
          </div>
      </div>
      <div class="fc gaps" v-else>
        <div 
          class="fc gap-item"
          :class="{right: item.submit_result === 1}"
          v-for="(item, index) in questionObj.submit_answer"
          :key="item.option_id"
        >
          <div class="fr gap-answer">
            <span class="no">{{index + 1}}、</span><span class="latex">{{item.value}}</span>
            <img src="../assets/imgs/right.png" alt="" v-if="item.submit_result === 1">
            <img src="../assets/imgs/error.png" v-else alt="">
          </div>
          <div class="fr gap-right-answer">
            <span>正确答案：</span>
            <span 
              v-for="(cItem, cIndex) in questionObj.content[index].answer_content_latex" 
              :key="cItem.option_id" 
              class="latex"
            >{{cItem}}<span v-if="cIndex + 1 !== questionObj.content[index].answer_content_latex.length">，</span></span>
          </div>
        </div>
      </div>
    </section>
    <div>
    </div>
    <article class="fc" v-if="list.length && questionObj.question_explain && questionObj.question_explain.length>0">
      <div class="bg"></div>
      <div class="fc section">
        <div class="fr section-header">
          <img src="../assets/imgs/jiexi_icon.png" alt="">
          <span>解析</span>
        </div>
        <p v-html="questionObj.question_explain"></p>
      </div>
    </article>
    <article class="fc" v-if="list.length && questionObj.answer_videos&& questionObj.answer_videos.length>0">
      <div class="bg"></div>
      <div class="fc section">
        <div class="fr section-header">
          <img src="../assets/imgs/jieximovie_icon.png" alt="">
          <span>解析视频</span>
        </div>
        <video
            :src="'//' + questionObj.answer_videos"
            controls
            width="70%"
            height="auto"
        ></video>
      </div>
    </article>
    <footer v-if="list.length">
      <div class="btngroup" v-if="activeIndex === 0 && pindex==0" >
        <van-button block type="primary" color="#4D78FF" round  class="btn" @click="next">下一题</van-button>
      </div>
      <div class="btngroup" v-else>
          <van-button block type="primary" round class="plainbtn" @click="previous" >上一题</van-button>
          <van-button block type="primary" color="#4D78FF" round @click="next">{{activeIndex + 1 === list.length && pindex== alllist.length-1 ? '返回首页' : '下一题'}}</van-button>
      </div>
    </footer>
    <!-- <van-popup v-model="show" position="bottom" round>
      <div class="fc card-top">
        <div class="fr card-title">
          <span>答题卡</span>
          <img src="../assets/imgs/close.png" alt="" @click="show = false">
        </div>
        <div class="fr card-icons">
          <div class="fr">
            <span class="right icon"></span>
            <span>答对</span>
          </div>
          <div class="fr">
            <span class="error icon"></span>
            <span>答错</span>
          </div>
        </div>
      </div>
      <div class="fr card-bottom">
        <span 
          v-for="(item, index) in list"
          :key="item.question_id"
          :class="{'error': item.submit_result !== 1}"
          @click="handleCardItem(index)"
        >{{index+1}}</span>
      </div>
    </van-popup> -->
  </div>
</template>
<script>
import {reportResult} from 'api'
import {ANSWER_OPTIONS} from 'utils/config'
import Nav from 'comps/Nav.vue'

export default {
  name: 'QuestionResult',
  data() {
    return {
      title: '',
      alllist:[
        {questions:[]}
      ],
      grade_name: '',
      serial_name: '',
      course_name: '',
      list: [],
      activeIndex: 0,
      pindex:0,
      options: ANSWER_OPTIONS,
      answers: [],
      query: {},
      token:'',
      show: false,
      isShowScroll: false,
      isEndScroll: false,
      left: 0,
      width: 0,
      questionW: 0,
      isShowScrollquestion:[]
    }
  },
  created () {
    this.query = this.$route.query;
    this.token = JSON.parse(this.$cookie.get('breakthrouth_userinfo')).token
    this.pindex=parseInt(this.query.pindex)
    this.activeIndex = parseInt(this.query.index)
    this.result(this.query)
  },
  watch: {
    questionObj: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            if (this.commonsVariable.isMathjaxConfig === false) {
              // 如果：没有配置MathJax
              this.commonsVariable.initMathjaxConfig();
            }
            let elem = this.$refs.mathjaxContainer;
            this.commonsVariable.MathQueue(elem);
            this.isShowScroll = false
            this.renderLatex()
          })
          let elem = document.querySelector('.answer-question')
          elem.scrollTo(0, 0)
          
        }
      },
      immediate: true
    }
  },
  computed: {
    questionObj() {
      return  this.list[this.activeIndex]
    },
    questionType() {
      if (!this.list.length) {
        return null
      }
      return this.questionObj.question_type === 101 ? '单选题' : this.questionObj.question_type === 200 ? '判断题' : '填空题'
    },
    // 是否最后一题
    isLastQuestion() {
      return this.activeIndex + 1 === this.list.length && this.pindex == this.alllist.length-1
    },
    styleObject() {
      return {
        width: this.questionW == 0 ? 'auto' : (this.questionW + 'px')
      }
    }
  },
  methods: {
    isAndroid() {
      var sua = this.detect.parse(navigator.userAgent);
      return sua.os.family === 'Android'
    },
    stylequestionlist(index){
      return {
        'overflow-x': this.isShowScrollquestion[index] ? 'scroll' : 'initial',
        'padding-bottom': this.isShowScrollquestion[index] ? '.5rem' : '0',
        'min-height': this.isShowScrollquestion[index] ? '2.3rem' : '0',
      }   
    },
    getMq() {
      if (!this.MQ) {
        this.MQ = MathQuill.getInterface(2);
      }
      return this.MQ;
    },
    /**渲染latex */
    renderLatex() {
      this.$nextTick(() => {
        let elems = document.querySelectorAll('.latex')
        elems.forEach(v => this.getMq().StaticMath(v))

        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.isShowScrollquestion = [];
          this.fixedScroll()
          let conElem = document.querySelector('section')
          let titlElem = document.querySelector('.question-title')

          let originW = conElem.offsetWidth - titlElem.offsetLeft*2 - titlElem.offsetWidth
          
          let newElem = document.querySelector('.question')
          if(newElem== undefined){
            this.isShowScroll = false
            return false;
          }
          if (!this.isAndroid()) {
            let elemW = newElem.offsetWidth
            // alert('elemW---' + elemW + '，originW----' + originW)
            if (elemW > originW) {
              this.questionW = originW
              let _width = originW / elemW * 100
              this.width = parseInt(_width)
              this.isShowScroll = true
              newElem.onscroll = (e) => {
                this.isEndScroll = false
                let distance = elemW - originW
                this.isShowScroll = false

                let scrollLeft = e.target.scrollLeft
                let _perc = scrollLeft / distance * 100
                let perc = Math.ceil(_perc)

                if (perc <= 100 - this.width) {
                  this.left = perc
                } else {
                  this.left = 100 - this.width
                }
                
                clearTimeout(this.timer2)
                this.timer2 = setTimeout(() => {
                  this.isEndScroll = true
                  this.isShowScroll = true
                }, 1500)
              }
            } else {
              this.questionW = 0
              this.isShowScroll = false
            }
          } else {
            this.questionW = originW
          }
      
        }, 1000)
      })
    },
    fixedScroll(){
      let newElem2 = document.querySelectorAll('.questionlist')
      if(newElem2== undefined){
        return false;
      }
      this.isShowScrollquestion = new Array(Number(newElem2.length))
      newElem2.forEach((item,idx)=>{
        if(item== undefined){
          this.isShowScrollquestion[idx] = false
          return false;
        }
        let scrollW2 = item.scrollWidth
        let elemW2 = item.offsetWidth
        if (scrollW2 > elemW2) {
          this.isShowScrollquestion[idx] = true
        } else {
          this.isShowScrollquestion[idx]= false
        }
      })
    },
    handleCardItem(index) {
      this.show = false
      this.activeIndex = index
    },
    sheet() {
      this.show = true
    },
    history() {
        this.$router.push({
          path: './index',
          query:{
            activity_id:this.query.activity_id,
          }
        })
    },
    previous() {
      this.isShowScrollquestion =[];
      this.questionW = 0
      if(this.activeIndex == 0){
        if(this.pindex >0){
          this.pindex--;
          this.list= this.alllist[this.pindex].questions
          this.initAnswers()
          this.activeIndex=this.list.length;
        }
      }
      this.activeIndex--
    },
    next() {
      this.isShowScrollquestion =[];
      if (!this.isLastQuestion) {
        this.questionW = 0
        this.activeIndex++
        if(this.activeIndex == this.list.length && this.pindex< this.alllist.length-1){
          this.pindex++;
          this.activeIndex=0;
          this.list= this.alllist[this.pindex].questions
          this.initAnswers()
        }
      } else {
        this.$router.push({
            path:'./index',
            query:{
              activity_id:this.query.activity_id,
            }
        })
      }
    },
    getOriginIndex(question_id, option_id) {
      if (option_id) { // 填空题
        return this.answers.findIndex(v => v.question_id === question_id && v.option_id === option_id)
      } else { // 选择题
        return this.answers.findIndex(v => v.question_id === question_id)
      }
    },
    // 获取我填写的答案
    getMyAnswer(type, item, content) {
      if (type === 101) { // 单选题
        let option_id = item.option_id
        let findIdx = content.findIndex(v => v.option_id === option_id)
        return this.options[findIdx]
      } else if (type === 300) { // 填空题
        return item.value
      } else {
        return null
      }
    },
    // 获取正确答案
    getOriginAnswer(item) {
      return this.options[item.findIndex(v=> v.flag === 1)]
    },
    async result(params) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      let {code, data} = await reportResult({...params,token:this.token})
      this.$toast.clear()
      if (code === 0) {
        this.title = data.title
        this.grade_name = data.grade_name
        this.serial_name = data.serial_name
        this.course_name = data.course_name
        this.alllist = data.list|| [];
        this.list =  this.alllist[this.pindex].questions
        // TODO:这里的初始化答案有什么用？
        this.initAnswers()
      }
    },
    // 初始化答案选项
    initAnswers() {
      this.list = this.list.map(v => {
        if (v.question_type === 101) {
          this.answers.push({
            question_id: v.question_id,
            type: v.question_type,
            option_id: '',
          })
        }else if(v.question_type === 200){
          this.answers.push({
            question_id: v.question_id,
            type: v.question_type,
            option_id: '',
            value:''
          })
        } else {
          v.content.forEach(cv => {
            this.answers.push({
              question_id: v.question_id,
              option_id: cv.option_id,
              type: v.question_type,
              value: ''
            })
          })
        }
        v.question = v.question.replaceAll('(　　　　　　)', `(　　　)`)
        return v
      })
    }
  },
  components: {
    Nav,
  },
}
</script>
<style lang="scss" scoped>
.answer-question {
  background: white;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  .header {
    // height: 6.19rem;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    box-shadow: 0rem 0.13rem 0.94rem 0rem rgba(28,28,28,0.09);
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
    .nav-bottom {
      flex-grow: 1;
      padding: 0 1rem;
      justify-content: space-between;
      align-items: center;
      color: #888;
      span {
        font-size: 0.81rem;
      }
      div {
        font-size: 0.75rem;
        img {
          width: 0.63rem;
          margin-right: 0.38rem;
        }
        b {
          font-size: 1.06rem;
          color: black;
        }
      }
    }
  }
  section {
    margin-top: 1rem;
    padding: 0 1rem;
    align-items: flex-start;
    .allcount{
      width: 68px;
      height: 22px;
      line-height: 22px;
      background: rgba(86,126,255,0.1);
      border-radius: 4px;
      font-size: 10px;
      color: #9DB5FF;
      &>span{
        font-size: 12px;
        color: #567EFF;    
      }
    }
    .title {
      width: 3.19rem;
      height: 1.38rem;
      line-height: 1.38rem;
      font-size: 0.75rem;
      background: rgba(240,73,67,0.25);
      color: #F04943;
      border-radius: 0.25rem;
    }
    .question {
      margin-top: 0.75rem;
      color: #333;
      justify-content: flex-start;
      line-height: 1.88rem;
      text-align: left;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      ::v-deep img {
        max-width: 100%;
      }
      &::-webkit-scrollbar {
        height: 3px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        background-color: #555;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        border-radius: 10px;
        background-color: #F5F5F5;
      }
    }
    .scroll {
      height: 3px;
      background: #a4a4a4;
      margin-right: auto;
      position: relative;
      top: -3px;
    }
    .list {
      margin-top: 2.19rem;
      width: 100%;
      .explo{
        margin-bottom:20px;
        text-align: left;
      }
      .item {
        align-items: center;
        margin-bottom: 2.06rem;
        &.error {
          span:first-child {
            background: #FF9612;
            border-color: #FF9612;
            color: white;
          }
        }
        &.right {
          span:first-child {
            background: #3EE372;
            border-color: #3EE372;
            color: white;
          }
        }
        span {
          &:first-child {
            display: inline-block;
            width: 2.69rem;
            height: 2.69rem;
            line-height: 2.69rem;
            border: 0.06rem solid #d4d4d4;
            border-radius: 50%;
            color: #7d7d7d;
            font-size: 0.88rem;
          }
          &:last-child {
            margin-left: 1.38rem;
            color: #555;
            font-size: 1rem;
          }
        }
      }
      .itemchoose{
        position: relative;
        .questionlist{
            margin-left: 1.38rem;
            color: #555;
            font-size: 1rem;
            width: 80%;
            text-align: left;
        }
        .scroll{
          position: absolute;
          width: 60%;
          bottom: 3px;
          left: 20%;
          top:auto
        }
      }
    }
    .gaps {
      margin-top: 2.19rem;
      margin-bottom: 2.06rem;
      width: 100%;
      .gap-item {
        margin-top: 1.75rem;
        &:first-child {
          margin-top: 0;
        }
        div {
          align-items: center;
        }
        img {
          width: 0.75rem;
          margin-left: 1.5rem;
        }
        .gap-answer {
          color: #FF9612;
          border-bottom: 0.06rem solid #F4F7F9;
          padding: 0 0.94rem 0.19rem;
          .no {
            color: #333333;
          }
        }
        &.right {
          .gap-answer {
            color: #3EE372;
          }
        }
        .gap-right-answer {
          margin-top: 1.13rem;
          padding: 0 0.94rem;
          flex-wrap: wrap;
          span {
            color: #5B5B5B;
          }
          .latex {
            color: #3EE372;
          }
        }
      }
    }
  }
  article {
    .bg {
      height: 0.63rem;
      background: #F4F7F9;
    }
    .section {
      padding: 1.56rem 1rem;
      align-items: center;
      .section-header {
        align-items: center;
        width: 100%;
        img {
          width: 1.25rem;
        }
        span {
          color: black;
          margin-left: 0.6rem;
          font-size: 1rem;
          font-weight: bold;
        }
      }
      p {
        width: 100%;
        margin-top: 0.81rem;
        font-size: 1rem;
        color: black;
        line-height: 1.5;
        text-align: left;
        overflow-x: auto;
        padding-bottom: 0.5rem;
        ::v-deep img {
          max-width: 100%;
        }    
        &::-webkit-scrollbar {
          height: 3px;
          background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
          background-color: #555;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
          border-radius: 10px;
          background-color: #F5F5F5;
        }
      }
      video {
        margin-top: 1.13rem;
      }
      .scroll {
        height: 3px;
        background: #a4a4a4;
        margin-right: auto;
        position: relative;
        top: -3px;
      }
    }
  }
  footer {
    padding-bottom: 2.69rem;
    .btn {
      display: inline-block;
      width: 21.44rem;
      height: 2.75rem;
      line-height: 2.75rem;
      background: #F04943;
      border-radius: 1.38rem;
      color: white;
    }
    .btns {
      justify-content: space-between;
      padding: 0 1rem;
      span {
        display: inline-block;
        width: 10rem;
        height: 2.75rem;
        line-height: 2.75rem;
        border-radius: 1.38rem;
        &:first-child {
          background: rgba(240,73,67,0.25);
          color: #F04943;
        }
        &:last-child {
          background: #F04943;
          color: white;
        }
      }
    }
  }
  .van-popup {
    box-sizing: border-box;
    .card-top {
      .card-title {
        padding: 1.56rem 0 0.69rem;
        position: relative;
        margin-top: 0.88rem;
        span {
          margin: 0 auto;
          color: black;
          font-size: 1rem;
          font-weight: bold;
        }
        img {
          position: absolute;
          right: 1rem;
          top: 50%;
          bottom: 0;
          transform: translateY(-50%);
          width: 2.75rem;
          height: 2.75rem;
        }
      }
      .card-icons {
        justify-content: center;
        margin-bottom: 0.69rem;
        div {
          align-items: center;
          margin-left: 1.56rem;
          font-size: 0.81rem;
          color: black;
          &:first-child {
            margin-left: 0;
          }
          .icon {
            display: inline-block;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            margin-right: 0.31rem;
            &.right {
              background: #53C87E;
            }
            &.error {
              background: #FF9612;
            }
          }
        }
      }
    }
    .card-bottom {
      flex-wrap: wrap;
      padding: 0 0.03rem 0.5rem;
      margin-top: 2.06rem;
      span {
        display: inline-block;
        width: 2.63rem;
        height: 2.63rem;
        line-height: 2.63rem;
        border-radius: 50%;
        background: #53C87E;
        margin: 0 0.97rem 1.44rem;
        color: white;
        &.error {
          background: #FF9612;
        }
      }
    }
  }
  .btngroup{
    margin: 0 1rem;
  }
}
</style>
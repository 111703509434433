<template>
  <span class="gap-input" ref="child">
    <span
      class="answer"
      v-html="answers.value"
      @click.stop="handleFocus"
    ></span>
    <span class="placeholder" v-if="!childAnswers.value" @click.stop="handleFocusPlaceholder">请在此输入答案</span>
  </span>
</template>

<script>
import { deepClone } from "utils/util";

export default {
  data() {
    return {
      answers: Object.assign({}, this.childAnswers),
      // 默认显示input
      hideInput: false
    };
  },
  props: {
    childAnswers: {
      type: Object,
      default: () => {}
    },
    gapIndex: {
      type: Number,
      default: 0
    },
    pc: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    let answerElem = this.$refs.child.querySelector('.answer')
    let _answers = deepClone(this.childAnswers)
    this.answerMathField = this.getMq().MathField(answerElem, {
      handlers: {
        edit: () => {
          var enteredMath = this.answerMathField.latex(); // Get entered math in LaTeX format
          _answers.value = enteredMath
          this.$emit('update:childAnswers', _answers)
        },
      },
    });

    if (this.pc) {
      return
    }
    // textInput
    if (this.isAndroid()) {
      this.$refs.child.addEventListener('input', (e) => {
        let data = e.data
        let keycode = data.charCodeAt(0)
        
        data = data.slice(data.length - 1)

        if (keycode === 9) { // 按tab键
          e.preventDefault()
          this.$emit('handleBlur')
          $('.answer .mq-cursor').remove()
        } else {
          if (this.isAndroid()) {
            this.$emit('selectedTemp', data)
          } else {
            e.preventDefault()
            this.$emit('selectedTemp', data)
          }
        }
      })
    } else {
      this.$refs.child.addEventListener('textInput', (e) => {
      let data = e.data
      let keycode = data.charCodeAt(0)
      
      if (keycode === 9) { // 按tab键
        e.preventDefault()
        this.$emit('handleBlur')
        $('.answer .mq-cursor').remove()
      } else {
        if (this.isAndroid()) {
          this.$emit('selectedTemp', data)
        } else {
          e.preventDefault()
          this.$emit('selectedTemp', data)
        }
      }
    })
    }
  },
  methods: {
    isAndroid() {
      var sua = this.detect.parse(navigator.userAgent);
      return sua.os.family === 'Android' && sua.device.type === 'Desktop'
    },
    getMq() {
      if (!this.MQ) {
        this.MQ = MathQuill.getInterface(2);
      }
      return this.MQ;
    },
    handleFocusPlaceholder() {
      let mathField = this.getMq().MathField(this.$refs.child.querySelector('.answer'));
      mathField.focus()
      this.handleFocus()
    },
    handleFocus() {  
      this.$emit('focusGapInput', this.gapIndex)
    }
  },
};
</script>

<style lang="scss" scoped>
.gap-input {
  position: relative;
  .answer {
    border: none;
    border-bottom: 2px solid #F4F7F9;
    text-align: left;
    box-sizing: border-box;
    box-shadow: none;
    display: inline-block;
    font-size: 18px;
    padding: 5px 0;
    width: 100%;
  }
  .placeholder {
    position: absolute;
    left: 0;
    top: 0;
    color: lightgrey;
  }
}
</style>
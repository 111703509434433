import request from 'utils/request'
export function login(data) {
  return request({
    url: '/studentapi/activity/login',
    data,
    method: 'post'
  })
}
// 发送验证码
export function sendCode(data) {
  return request({
    url: '/studentapi/sms/send',
    params: data,
  })
}
// 学生参与活动附属数据上报
export function studentDetail(data) {
  return request({
    url: '/studentapi/upload/student/detail',
    data,
    method: 'post'
  })
}

//活动列表
export function activityList(data) {
  return request({
    url: '/studentapi/activity/list',
    data,
    method: 'post'
  })
}

//关卡详情
export function reportDetail(data) {
  return request({
    url: '/studentapi/activity/report/detail',
    data,
    method: 'post'
  })
}
// 开始答卷
export function reportStart(data) {
  return request({
    url: '/studentapi/activity/report/start',
    data,
    method: 'post'
  })
}
// 提交试卷
export function reportSubmit(data) {
  return request({
    url: '/studentapi/activity/report/submit',
    params: data
  })
}
// 查看答案
export function reportResult(data) {
  return request({
    url: '/studentapi/activity/report/result',
    params: data
  })
}
// 查看报告
export function reportAnalysis(data) {
  return request({
    url: '/studentapi/activity/report/analysis',
    params: data
  })
}
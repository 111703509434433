<template>
  <nav class="fr">
    <slot name="left"></slot>
    <span>{{title}}</span>
    <slot name="right"></slot>
    <slot name="rightIcons"></slot>
  </nav>
</template>
<script>
export default {
  props: {
    title: String
  }
}
</script>
<style lang="scss" scoped>
nav {
  height: 3.56rem;
  justify-content: space-between;
  align-items: center;
  background: white;
  position: relative;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0px 0px 6px 2px rgba(166,168,176,0.21);
  .left-icon, .right-icon {
    position: absolute;
    width: 2.75rem;
  }
  .left-icon {
    left: 1rem;
  }
  .right-icon {
    right: 1rem;
  }
  .right-icons {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 2.75rem;
      height: 2.75rem;
      margin-right: 0.87rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  span {
    margin: 0 auto;
    color: #333;
    font-size: 1rem;
  }
}
</style>
<template>
  <div class="answer-question" @click="handleBlur">
    <div class="header fc">
      <Nav :title="title">
        <template #left>
          <img src="../assets/imgs/close.png" class="left-icon" @click="$router.go(-1)">
        </template>
        <template #right>
          <img src="../assets/imgs/answer_sheet.png" class="right-icon" @click="sheet">
        </template>
      </Nav>
    </div>
    <section class="fc" ref="mathjaxContainer" v-if="list.length">
      <div class="fr nav-bottom">
        <p class="allcount"><span> 题数 {{activeIndex + 1}} </span> /{{list.length}}</p>
        <div class="deviation" v-show="deviation && deviation!=''"> 答题时间 - {{ deviation }}</div>
      </div>
      <div class="question" v-html="questionObj.question" :style="styleObject"></div>
      <div class="scroll" v-if="isShowScroll" :style="{marginLeft: left + '%', width: width + '%'}"></div>
      <div class="list fc" v-if="questionObj.question_type === 101">
        <div 
          class="fr item itemchoose"
          :class="{active: answers[getOriginIndex(questionObj.question_id, null)].option_id === item.option_id}"
          v-for="(item, index) in questionObj.content" 
          :key="item.option_id"
          @click="handleItem(item)"
        >
          <span>{{options[index]}}</span>
          <span v-html="item.value" class="questionlist" :style="stylequestionlist(index)"></span>
          <div class="scroll" v-if="isShowScrollquestion[index]" ></div>
        </div>
      </div>
      <div class="list fc" v-if="questionObj.question_type === 200">
          <div 
            class="fr item"
            :class="{active: answers[getOriginIndex(questionObj.question_id, null)].value!==''&& answers[getOriginIndex(questionObj.question_id, null)].value ==1}"
            @click="handleSelectedJudge(questionObj,'1')"
          >
            <span></span>
            <span>正确</span>
          </div>
          <div 
            class="fr item"
            :class="{active: answers[getOriginIndex(questionObj.question_id, null)].value!==''&& answers[getOriginIndex(questionObj.question_id, null)].value ==0}"
            @click="handleSelectedJudge(questionObj,'0')"
          >
            <span></span>
            <span> 错误</span>
          </div>
      </div>
      <div class="fc gaps" v-if="questionObj.question_type === 300">
        <span class="gap-item" v-for="(item, index) in questionObj.content" :key="item.option_id">
          <LatexGapInput
            :childAnswers.sync="answers[getOriginIndex(questionObj.question_id, item.option_id)]"
            :gapIndex="index"
            ref="latexBox"
            :key="item.question_id + item.option_id"
            @selectedTemp="handleSelectedTemp"
            @focusGapInput="handleFocusGapInput"
            :pc="isPc"
          />
        </span>
      </div>
    </section>
    <footer v-if="list.length">
      <span v-if="activeIndex === 0" class="btn" @click="next">下一题</span>
      <div v-else class="btns fr">
        <span @click="previous">上一题</span>
        <span @click="next">{{activeIndex + 1 === list.length ? '提交试卷' : '下一题'}}</span>
      </div>
    </footer>
    <p class="ghost" v-if="isShowGhost">幽灵控件</p>
    <van-popup v-model="show" position="bottom" round>
      <div class="fr card-top">
        <span>答题卡</span>
        <img src="../assets/imgs/close.png" alt="" @click="show = false">
      </div>
      <div class="fr card-bottom">
        <span 
          v-for="(item, index) in list"
          :key="index"
          :class="{'no-answer': isunFinish(item)}"
          @click="handleCardItem(index)"
        >{{index + 1}}</span>
      </div>
    </van-popup>
    <img src="../assets/imgs/icon_symbol.png" alt="" class="symbol-btn" :class="{pc: this.isPc}" :style="{right: right + 'px'}" v-if="isShowSymbol" @click="handleShowBox" />
    <div class="fc box" v-if="isShowbox" :style="{top: scrollTop + 'px'}" @click.stop="() => null">
      <div 
        class="boxs fr" 
        v-for="(item, index) in math" 
        :key="index"
      >
        <span 
          v-for="cItem in item" 
          class="box-item fr latex" 
          :key="cItem.v"
          @click="handleSelectedTemp(cItem.v)"
        >{{cItem.k}}</span>
      </div>
      <img src="../assets/imgs/close2.png" alt="" @click="isShowbox = false">
    </div>
  </div>
</template>
<script>
import {reportDetail,reportStart, reportSubmit} from 'api'
import {ANSWER_OPTIONS} from 'utils/config'
import Nav from 'comps/Nav.vue'
import LatexGapInput from 'comps/latexGapInput'
import {MATHALL} from 'utils/math'
import {countTime} from 'utils/util'
export default {
  name: 'AnswerQuestion',
  data() {
    return {
      title: '',
      grade_name: '',
      serial_name: '',
      course_name: '',
      list: [],
      activeIndex: 0,
      options: ANSWER_OPTIONS,
      answers: [],
      text: '',
      query: {},
      token:'',
      show: false,
      isShowSymbol: false,
      math: MATHALL,
      isShowbox: false,
      gapIndex: 0,
      scrollTop: 0,
      isShowGhost: false,
      // 默认是课后测试
      isAfterClass: true,
      isPc: true,
      right: 0,
      duration_second:0,
      deviation:'',
      timer:null,
      isShowScroll: false,
      left: 0,
      width: 0,
      // 页面进入的开始时间
      startTime: null,
      countIndex:0,
      // 试卷时长
      duration: 0,
      isShowScrollquestion:[]
    }
  },
  created () {
    clearTimeout(this.timer)
    // this.isAfterClass = !!queryJson.course_id
    this.query = this.$route.query
    this.token = JSON.parse(this.$cookie.get('breakthrouth_userinfo')).token
    this.detail(this.query)
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.isShowGhost = false
      // clearTimeout(this.timer)
      // this.timer = setTimeout(() => {
        this.scrollTop = document.documentElement.scrollTop
  
        let headerElem = document.querySelector('.header')
        let headerH = headerElem.offsetHeight
  
        let sysmbolBtn = document.querySelector('.symbol-btn')
        if (sysmbolBtn) {
          sysmbolBtn.style.top = headerH + this.scrollTop + 'px'
        }
      // }, 50)
    })

    var sua = this.detect.parse(navigator.userAgent);
    let family = sua.os.family.toLowerCase()
    this.isPc = family.indexOf('window') >= 0 || family.indexOf('mac') >= 0
  },
  watch: {
    activeIndex: {
      handler(val) {
        // console.log('val---', val);
        this.fixedScroll()
      },
      immediate: true
    },
    questionObj: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            if (this.commonsVariable.isMathjaxConfig === false) {
              // 如果：没有配置MathJax
              this.commonsVariable.initMathjaxConfig();
            }
            let elem = this.$refs.mathjaxContainer;
            this.commonsVariable.MathQueue(elem);
          })
          
          let elem = document.querySelector('.answer-question')
          elem.scrollTo(0, 0)
          this.fixedScroll()
        }
      },
      immediate: true
    }
  },
  computed: {
    questionObj() {
      return this.list[this.activeIndex] 
    },
    questionType() {
      if (!this.list.length) {
        return null
      }
      return this.questionObj.question_type === 101 ? '单选题' :this.questionObj.question_type === 200 ? '判断题' : '填空题'
    },
    // 是否最后一题
    isLastQuestion() {
      return this.activeIndex + 1 === this.list.length
    },
    styleObject() {
      return {
        'overflow-x': this.isShowScroll ? 'scroll' : 'initial'
      }
    },

  },
  methods: {
    isAndroid() {
      var sua = this.detect.parse(navigator.userAgent);
      return sua.os.family === 'Android'
    },
    stylequestionlist(index){
      return {
        'overflow-x': this.isShowScrollquestion[index] ? 'scroll' : 'initial',
        'padding-bottom': this.isShowScrollquestion[index] ? '.5rem' : '0',
        'min-height': this.isShowScrollquestion[index] ? '2.3rem' : 'normal',
      }   
    },
    fixedScroll() {
      if (!this.isAndroid()) {
        clearTimeout(this.scrollTimer)
        this.isShowScrollquestion= [];
        this.scrollTimer = setTimeout(() => {
          let newElem = document.querySelector('.question')
          if(newElem== undefined){
            this.isShowScroll = false
            return false;
          }
          let scrollW = newElem.scrollWidth
          let elemW = newElem.offsetWidth

          if (scrollW > elemW) {
            let _width = elemW / scrollW * 100
            this.width = parseInt(_width)
            this.isShowScroll = true
            newElem.onscroll = (e) => {
              let distance = scrollW - elemW
              this.isShowScroll = false
              let scrollLeft = e.target.scrollLeft
              let _perc = scrollLeft / distance * 100
              let perc = Math.ceil(_perc)

              if (perc <= 100 - this.width) {
                this.left = perc
              } else {
                this.left = 100 - this.width
              }
              
              clearTimeout(this.scrollTimer2)
              this.scrollTimer2 = setTimeout(() => {
                this.isShowScroll = true
              }, 1500)
            }
          } else {
            this.isShowScroll = false
          }

          let newElem2 = document.querySelectorAll('.questionlist')
          if(newElem2== undefined){
            return false;
          }
          this.isShowScrollquestion = new Array(Number(newElem2.length))
          newElem2.forEach((item,idx)=>{
            if(item== undefined){
              this.isShowScrollquestion[idx] = false
              return false;
            }
            let scrollW2 = item.scrollWidth
            let elemW2 = item.offsetWidth
            if (scrollW2 > elemW2) {
              this.isShowScrollquestion[idx] = true
            } else {
              this.isShowScrollquestion[idx]= false
            }
          })
        }, 1000)
      }
    },
    handleBlur() {
      this.isShowGhost = false
      this.isShowSymbol = false
    },
    handleFocusGapInput(index) {
      if (this.isShowbox) {
        if (index !== this.gapIndex) {
          this.gapIndex = index
          this.isShowSymbol = true
          this.isShowbox = false
        }
      } else {
        this.gapIndex = index
        this.isShowSymbol = true

        let headerElem = document.querySelector('.header')
        let headerH = headerElem.offsetHeight
        
        let height = document.documentElement.clientHeight

        let answerElem = document.querySelector('.answer-question')
        let answerHeight = answerElem.offsetHeight

        let topHeight = answerHeight - height + headerH
        
        
        this.$nextTick(() => {
          let symbolBtn = document.querySelector('.symbol-btn')
          symbolBtn.style.top = topHeight + 'px'
        })
      }

      if (this.isPc) {
        this.$nextTick(() => {
          let parentElem = document.querySelector('.answer-question')
          let symbolBtn = document.querySelector('.symbol-btn')
          let viewW = parentElem.offsetWidth
          let leftW = symbolBtn.offsetLeft
          let distanceW = (leftW - viewW) * 0.5 + 43
          if (this.right === 0) {
            this.right = distanceW
          }
        })
        return
      }
      this.isShowGhost = true
      setTimeout(() => {
        let parentElem = document.querySelector('.answer-question')
        let height = document.querySelector('section').offsetHeight

        parentElem.scrollTo(0, height)
      }, 100)
    },
    handleShowBox() {
      this.isShowSymbol = false
      this.isShowbox = true
      this.renderLatex()
    },
    /**选择模板 */
    handleSelectedTemp(val) {
      let containerElem = this.$refs.latexBox
      let childElem = containerElem[this.gapIndex].$refs.child
      let elem = childElem.querySelector('.answer')

      let mathField = this.getMq().MathField(elem);
      mathField.write(val)

      if (val === '^\\circ') { // 当前点击的是度
        mathField.moveToRightEnd().focus()
      } else { // 其他字符
        let elems = childElem.querySelectorAll('.answer .mq-root-block [mathquill-command-id]')
        let id, idx = 0, maxId = 0
        elems.forEach((v, i) => {
          id = v.getAttribute('mathquill-command-id')
          if (id > maxId) {
            idx = i
            maxId = id
          }
        })
        $(elems[idx]).mousedown().mouseup()

        if (val === '{}\\frac{}{}') {
          mathField.keystroke('Left')
        }
      }

      if (this.isPc) {
        return
      }
      this.isShowGhost = true
      setTimeout(() => {
        let parentElem = document.querySelector('.answer-question')
        let height = document.querySelector('section').offsetHeight

        parentElem.scrollTo(0, height)
      }, 100)      
    },
    getMq() {
      if (!this.MQ) {
        this.MQ = MathQuill.getInterface(2);
      }
      return this.MQ;
    },
    /**渲染latex */
    renderLatex() {
      this.$nextTick(() => {
        let elems = document.querySelectorAll('.latex')
        elems.forEach(v => this.getMq().StaticMath(v))
      })
    },
    isunFinish(item) {
      if (item.question_type === 101) {
        return !this.answers.find(v => v.question_id === item.question_id).option_id
      }else if (item.question_type === 200) {
        return this.answers.find(v => v.question_id === item.question_id).value===''
      }  else {
        return item.content.some(v => this.answers.find(cv => cv.option_id === v.option_id).value === '')
      }
    },
    handleCardItem(index) {
      this.show = false
      this.activeIndex = index
    },
    sheet() {
      this.show = true
    },
    previous() {
      this.isShowSymbol = false
      this.isShowbox = false
      this.activeIndex--
    },
    next() {
      this.isShowSymbol = false
      this.isShowbox = false
      if (!this.isLastQuestion) {
        this.activeIndex++
      } else {
        this.handleSubmit()
      }
    },
    // 提交试卷
    handleSubmit() {
      this.answers = this.answers.map(v => {
        if (v.type === 300) {
          // v.value = v.value.toLowerCase()
          v.value = v.value.replace(/^(\\\s)+|(\\\s)+$/g, '')
        }
        return v
      })
      let unFinish = this.answers.some(v => v.type === 101 ? !v.option_id : !v.value)
      let msg = `${unFinish ? '当前试卷还未完成，' : ''}是否确认提交试卷`
      this.$dialog.confirm({
        message: msg,
      }).then(async () => {
        this.reportSubmitFn()
      }).catch(() => {
      });
    },
     // 提交试卷 函数
     async reportSubmitFn(){
      let res = await reportSubmit({
          answers: JSON.stringify(this.answers),
          token:this.token,
          ...this.query
        })
        if (res.code === 0) {
          clearTimeout(this.timer)
          sessionStorage.setItem('submitebackdata', JSON.stringify(res.data))
          setTimeout(() => {
            this.$router.replace({
              path: '/result',
              query: {
                ...this.query
              }
            })
          }, 200)
        }
     },
    /**选择题 */
    handleItem(item) {
      let index = this.getOriginIndex(this.questionObj.question_id, null)
      this.answers[index].option_id = item.option_id
    },
    /**判断题 */
    handleSelectedJudge(item,e) {
      let index = this.getOriginIndex(this.questionObj.question_id, null)
      this.answers[index].value = e
    },
    getOriginIndex(question_id, option_id) {
      if (option_id) { // 填空题
        return this.answers.findIndex(v => v.question_id === question_id && v.option_id === option_id)
      } else { // 选择题
        return this.answers.findIndex(v => v.question_id === question_id)
      }
    },
    async detail(params) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      let {code, data} = await reportDetail({...params,token:this.token})
      this.$toast.clear()
      if (code === 0) {
        this.title = data.title
        this.grade_name = data.grade_name
        this.serial_name = data.serial_name
        this.course_name = data.course_name
        this.list = data.list || []
        this.initAnswers()
        // 开始答卷
        let resp = await reportStart({...params,token:this.token})
        if(resp.code == 0){
          if( resp.data.test_duration_second >0){
            this.duration = resp.data.test_duration_second
            this.startTimeout(0)
          }	
        }
      }else{
        this.$router.go(-1)
      }
    },
    // 开启定时器
    startTimeout(interval) {
      if (!this.startTime) {
        this.startTime = new Date().getTime()
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.duration--
        if (this.duration === 0) {
          clearTimeout(this.timer)
          this.$toast('答卷时间到，系统将自动提交试卷')
          this.answers = this.answers.map(v => {
            if (v.type === 300) {
              v.value = v.value.replace(/^(\\\s)+|(\\\s)+$/g, '')
            }
            return v
          })
          this.reportSubmitFn()
        } else {
         
          let endTime = new Date().getTime()
          this.countIndex++
          let deviation = endTime - this.startTime - this.countIndex * 1000
          this.startTimeout(deviation)
         
        }
        this.deviation=countTime(this.duration)
      }, 1000 - interval)
    },
    // 初始化答案选项
    initAnswers() {
      this.list = this.list.map(v => {
        if (v.question_type === 101) {
          this.answers.push({
            question_id: v.question_id,
            type: v.question_type,
            option_id: '',
          })
        }else if(v.question_type === 200){
          this.answers.push({
              question_id: v.question_id,
              type: v.question_type,
              option_id:  v.content.option_id,
              value: v.content.value
            })
        } else {
          v.content.forEach(cv => {
            this.answers.push({
              question_id: v.question_id,
              option_id: cv.option_id,
              type: v.question_type,
              value: ''
            })
          })
        }
        v.question = v.question.replaceAll('(　　　　　　)', `(　　　)`)
        this.fixedScroll()
        return v
      })
    }
  },
  destroyed(){
    clearTimeout(this.timer)
  },
  components: {
    Nav,
    LatexGapInput
  },
}
</script>
<style lang="scss" scoped>
.answer-question {
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  background: white;
  .header {
    // height: 6.19rem;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    box-shadow: 0rem 0.13rem 0.94rem 0rem rgba(28,28,28,0.09);
    background: white;
    position: sticky;
    top: 0;
    z-index: 2;

  }
  section {
    margin-top: 1rem;
    padding: 0 1rem;
    align-items: flex-start;
    .title {
      width: 3.19rem;
      height: 1.38rem;
      line-height: 1.38rem;
      font-size: 0.75rem;
      background: rgba(240,73,67,0.25);
      color: #F04943;
      border-radius: 0.25rem;
    }
    .nav-bottom {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: #888;
        font-size: 0.75rem;
        .allcount{
          width: 68px;
          height: 22px;
          line-height: 22px;
          background: rgba(86,126,255,0.1);
          border-radius: 4px;
          color: #9DB5FF;
          &>span{
            font-size: 12px;
            color: #567EFF;    
          }
        }
        .deviation{
          color:#EE3535;
          background: url(../assets/imgs/deviation.png) no-repeat left center; 
          background-size: 13px;
          padding-left: 17px;
        }
    }

    .question {
      margin-top: 0.75rem;
      color: #333;
      justify-content: flex-start;
      line-height: 1.88rem;
      text-align: left;
      overflow-y: hidden;
      padding-bottom: 0.5rem;
      width: 100%;
      ::v-deep img {
        max-width: 100%;
        height: auto;
      }
      &::-webkit-scrollbar {
        height: 3px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        background-color: #555;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        border-radius: 10px;
        background-color: #F5F5F5;
      }
    }
    .scroll {
      height: 3px;
      background: #a4a4a4;
      margin-right: auto;
      position: relative;
      top: -3px;
    }
    .list {
      margin-top: 2.19rem;
      width: 100%;
      .item {
        align-items: center;
        margin-bottom: 2.06rem;
        &.active {
          span:first-child {
            background: #4D78FF;
            border-color: #4D78FF;
            color: white;
          }
        }
        span {
          &:first-child {
            display: inline-block;
            width: 2.69rem;
            height: 2.69rem;
            line-height: 2.69rem;
            border: 0.06rem solid #d4d4d4;
            border-radius: 50%;
            color: #7d7d7d;
            font-size: 0.88rem;
          }
          &:last-child {
            margin-left: 1.38rem;
            color: #555;
            font-size: 1rem;
            width: 80%;
          }
        }

      }
      .itemchoose{
        position: relative;
        .questionlist{
            margin-left: 1.38rem;
            color: #555;
            font-size: 1rem;
            width: 80%;
            text-align: left;
        }
        .scroll{
          position: absolute;
          width: 60%;
          bottom: 3px;
          left: 20%;
          top:auto
        }
      }
    }
  }
  .gaps {
    margin-top: 2.19rem;
    margin-bottom: 2.06rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    .gap-item {
      display: inline-block;
      box-sizing: border-box;
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .van-cell--borderless::after, .van-cell:last-child::after {
      display: inherit;
    }
  }
  footer {
    padding-bottom: 2.69rem;
    .btn {
      display: inline-block;
      width: 21.44rem;
      height: 2.75rem;
      line-height: 2.75rem;
      background: #4D78FF;
      border-radius: 1.38rem;
      color: white;
    }
    .btns {
      justify-content: space-between;
      padding: 0 1rem;
      span {
        display: inline-block;
        width: 10rem;
        height: 2.75rem;
        line-height: 2.75rem;
        border-radius: 1.38rem;
        &:first-child {
          border:1px solid #4D78FF;
          color: #4D78FF;
        }
        &:last-child {
          background: #4D78FF;
          color: white;
        }
      }
    }
  }
  .ghost {
    height: 50vh;
    color: white;
    background: transparent;
  }
  .van-popup {
    box-sizing: border-box;
    .card-top {
      padding: 1.63rem 0 1.38rem;
      position: relative;
      span {
        margin: 0 auto;
        color: black;
        font-size: 1rem;
        font-weight: bold;
      }
      img {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2.75rem;
      }
    }
    .card-bottom {
      flex-wrap: wrap;
      padding: 0 0.03rem 0.5rem;
      margin-top: 1.38rem;
      span {
        display: inline-block;
        width: 2.63rem;
        height: 2.63rem;
        line-height: 2.63rem;
        border-radius: 50%;
        background: #3EE372;
        margin: 0 0.97rem 1.44rem;
        color: white;
        &.no-answer {
          background: #E6E6E6;
          color: #515151;
        }
      }
    }
  }
  .symbol-btn {
    position: fixed;
    right: 0;
    top: 6.45rem;
    width: 4.38rem;
    &.pc {
      width: 86px;
    }
  }
  .box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 9.2rem;
    z-index: 2;
    .boxs {
      background: #D5D8DE;
      justify-content: space-around;
      padding-top: 0.25rem;
      &:first-child {
        padding-top: 0.5rem;
      }
      &:nth-child(2) {
        padding-bottom: 0.5rem;
      }
      .box-item {
        color: #555;
        font-weight: bold;
        width: 2rem;
        height: 2.6rem;
        align-items: center;
        background: white;
        border-radius: 0.38rem;
      }
    }
    img {
      align-self: flex-end;
      width: 4.38rem;
    }
  }
}
</style>

// 答案选项
export const ANSWER_OPTIONS = [
  "A", "B", "C", "D", "E", "F", "G", 
  "H", "I", "J", "K", "L", "M", "N", 
  "O", "P", "Q", "R", "S", "T", 
  "U", "V", "W", "X", "Y", "Z"
];
// 所属年级
export const GRADE_LIST = [
  {
    id: 1,
    title: '小学一年级'
  },
  {
    id: 2,
    title: '小学二年级'
  },
  {
    id: 3,
    title: '小学三年级'
  },
  {
    id: 4,
    title: '小学四年级'
  },
  {
    id: 5,
    title: '小学五年级'
  },
  {
    id: 6,
    title: '小学六年级'
  },
  {
    id: 7,
    title: '初中一年级'
  },
  {
    id: 8,
    title: '初中二年级'
  },
  {
    id: 9,
    title: '初中三年级'
  },
  {
    id: 10,
    title: '高中一年级'
  },
  {
    id: 11,
    title: '高中二年级'
  },
  {
    id: 12,
    title: '高中三年级'
  },
]
// 是否过敏
export const TYPE_LIST = [
  {
    id: 0,
    title: '无'
  },
  {
    id: 1,
    title: '有'
  },
]
// 第几关
export const CUSTOM_OPTIONS = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
export const ENCRY_KEY = "Cm1cU6s5a5iZ8dFR";
/**
 *                             _ooOoo_
 *                            o8888888o
 *                            88" . "88
 *                            (| -_- |)
 *                            O\  =  /O
 *                         ____/`---'\____
 *                       .'  \\|     |//  `.
 *                      /  \\|||  :  |||//  \
 *                     /  _||||| -:- |||||-  \
 *                     |   | \\\  -  /// |   |
 *                     | \_|  ''\---/''  |   |
 *                     \  .-\__  `-`  ___/-. /
 *                   ___`. .'  /--.--\  `. . __
 *                ."" '<  `.___\_<|>_/___.'  >'"".
 *               | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *               \  \ `-.   \_ __\ /__ _/   .-` /  /
 *          ======`-.____`-.___\_____/___.-`____.-'======
 *                             `=---='
 *          ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *                     放下屠刀        立地成佛
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookie from "js-cookie";
Vue.config.productionTip = false

import { Circle,Popup,Toast,Field,Dialog,Overlay,Form,Button,Picker, RadioGroup, Radio} from 'vant';
Vue.use(Overlay);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Circle);
Vue.use(Form);
Vue.use(Button);
Vue.use(Picker);
Vue.use(Radio);
Vue.use(RadioGroup);
import './assets/css/index.scss'

import globalVariable from "./assets/js/globalVariable";
Vue.prototype.commonsVariable = globalVariable;
Vue.prototype.$cookie = Cookie
// 格式化useragent
import detect from '@/assets/js/detect'
Vue.prototype.detect = detect

const app = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
export default app
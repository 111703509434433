import axios from 'axios'
import app from '@/main'
import { Toast } from 'vant';
import router from '@/router'
import Cookie from "js-cookie";
const service = axios.create({
  validateStatus: function (status) {
    return status <= 500
  }
});

let URLS = [
  // 'http://139.217.130.3:802' 测试服
  {
    origin: 'cz.event.52shuxue.com', // 初中
    url: '//api.cz.52shuxue.com'
  },
  {
    origin: 'gz.event.52shuxue.com', // 高中
    url: '//api.pro.52shuxue.com'
  }
]
let baseURL = URLS.find(v => location.origin.indexOf(v.origin) >= 0)?.url || 'http://139.217.130.3:802'

service.defaults.baseURL = baseURL;
service.defaults.withCredentials = true
service.interceptors.request.use(config => {
  if (!config.data) {
    config.data = {}
  }
  let method = config.method
  if (method === 'get') {
    config.headers['content-type'] = 'application/x-www-form-urlencoded'
  }
  return config;
});

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, errMsg) => {
  // 状态码判断
  switch (status) {
    case 400:
      return '服务器不理解请求的语法'
    case 403:
      return '服务器拒绝请求'
    case 404:
      return '未找到请求的资源'
    case 405:
      return '禁用请求中指定的方法'
    case 500:
      return '服务器内部错误'
    case 503:
      return '服务不可用'
    default:
      break
  }
}

service.interceptors.response.use(({ status, data }) => {
  let errorMsg = errorHandle(status, data.msg)
  if (errorMsg) {
    console.log('网络错误');
    // Message.error('网络错误')
  } else if (data.code !== 0) {
    if (data && data.msg) {
      Toast(data.msg)
      if(data.error == 5001){
        Cookie.remove('breakthrouth_userinfo')
        window.location.reload()
      }
    } else {
      Toast('网络错误')
    }
  }
  return data
})

export default service;

import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from "js-cookie";
import Login from '../views/login.vue'
import StuInfo from '../views/submitstuInfo.vue'
import ActiveIntroduce from '../views/activeIntroduce.vue'
import ActiveIndex from '../views/activeIndex.vue'
// import BeforeClassList from '../views/BeforeClassList.vue'
import AnswerQuestion from '../views/AnswerQuestion.vue'
import Result from '../views/Result.vue'
// import AfterClassList from '../views/afterClassList.vue'
import QuestionResult from '../views/questionResult'
import AnswerSheet from '../views/answerSheet.vue'
import Report from '../views/report.vue'
import { GetArgsFromHref } from "../utils/util"
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/stuInfo',
    name: 'stuInfo',
    component: StuInfo
  },
  {
    path: '/activeIntroduce',
    name: 'activeIntroduce',
    component: ActiveIntroduce
  },
  {
    path: '/index',
    name: 'index',
    component: ActiveIndex
  },
  // {
  //   path: '/beforeclasslist',
  //   name: 'BeforeClassList',
  //   component: BeforeClassList
  // },
  {
    path: '/answerquestion',
    name: 'AnswerQuestion',
    component: AnswerQuestion
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  // {
  //   path: '/afterclasslist',
  //   name: 'AfterClassList',
  //   component: AfterClassList
  // },
  {
    path: '/questionresult',
    name: 'QuestionResult',
    component: QuestionResult
  },
  {
    path: '/answersheet',
    name: 'AnswerSheet',
    component: AnswerSheet
  },
  {
    path: '/report',
    name: 'report',
    component: Report
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
    var islogin=Cookies.get('breakthrouth_userinfo');
    var breakthrouth_activity_id=Cookies.get('breakthrouth_activity_id')
    var activity_id=GetArgsFromHref('activity_id'),source=GetArgsFromHref('source')||Cookies.get('breakthrouth_source')||'1';
    if(islogin && activity_id == breakthrouth_activity_id){  //登录过且活动id相同
      if(to.path=='/login'){
        next({
            path: '/index',
            query: {
              activity_id: activity_id,
              source:source
            }
        })     
      }else{
        next()
      }
    }else{
      if(to.path=='/login'){
        next()
      }else{
        next({
            path: '/login',
            query: {
              activity_id: activity_id,
              source:source
            }
        })
      }
    }
})
export default router

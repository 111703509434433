<template>
    <div class="height100vh">
        <div v-if="datainfo.title">
            <Nav title="闯关"></Nav>
            <div  class="indexmain">
                <img src="../assets/imgs/index_icon.png" alt="" class="indexicon">
                <!-- 第一次进入 -->
                <div  v-show="datainfo.pass_num==0 && datainfo.next_report_id!=0">
                    <p class="customs_pass"> {{ datainfo.title }} 第一关</p>
                    <div class="btngroup">
                        <van-button block type="primary" color="#4D78FF" round @click="handleNextPaper(datainfo.activity_id,datainfo.next_report_id)">开始闯关</van-button>
                    </div>
                </div>
                <!--闯过所有关卡 -->
                <div  v-show="datainfo.pass_num==datainfo.customs_pass_num && datainfo.next_report_id==0">
                    <p class="customs_pass"> 当前关卡 -  第{{customoptions[datainfo.customs_pass]}}关</p>
                    <p class="customs_pass_tips">恭喜您闯过了所有关卡，点击可查看报告</p>
                    <div class="btngroup">
                        <van-button block type="primary" round class="plainbtn" @click="lookbtn('answer',datainfo.activity_id)" >查看答案</van-button>
                        <van-button block type="primary" color="#4D78FF" round @click="lookbtn('report',datainfo.activity_id)">查看报告</van-button>
                    </div>
                </div>
                <!--中途闯关失败，包括一关未过 -->
                <div  v-show="(datainfo.pass_num<datainfo.customs_pass_num) && datainfo.next_report_id==0">
                    <p class="customs_pass"> 当前关卡 -  第{{customoptions[datainfo.customs_pass]}}关</p>
                    <p class="customs_pass_tips">您闯过了{{datainfo.pass_num}}关，请下次再接再厉！点击可查看报告</p>
                    <div class="btngroup">
                        <van-button block type="primary" round class="plainbtn" @click="lookbtn('answer',datainfo.activity_id)" >查看答案</van-button>
                        <van-button block type="primary" color="#4D78FF" round @click="lookbtn('report',datainfo.activity_id)">查看报告</van-button>
                    </div>
                </div>
                <!-- 继续闯关 -->
                <div v-show="datainfo.pass_num!=0 && (datainfo.pass_num<datainfo.customs_pass_num) && datainfo.next_report_id!=0">
                    <p class="customs_pass"> 第{{customoptions[datainfo.customs_pass]}}关</p>
                    <p>您已闯过了第{{customoptions[datainfo.pass_num]}}关卡，当前为第{{customoptions[datainfo.customs_pass]}}关卡，祝您成功！</p>
                    <div class="btngroup">
                        <van-button block type="primary" round class="plainbtn" @click="lookbtn('answer',datainfo.activity_id)" >查看答案</van-button>
                        <van-button block type="primary" color="#4D78FF" round @click="handleNextPaper(datainfo.activity_id,datainfo.next_report_id)">继续闯关</van-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="end">
            {{ msg }}
        </div>
    </div>
</template>
<script>
import Nav from 'comps/Nav'
import {activityList} from 'api'
import { CUSTOM_OPTIONS } from 'utils/config'
import { GetArgsFromHref } from "utils/util";
export default {
    name:'index',
    components: {
        Nav,
    },
    data(){
        return {
            token:'',
            datainfo:{},
            customoptions:CUSTOM_OPTIONS,
            msg:'',
            activity_id:'',
            source:'1'
        }
    },
    mounted () {
        window.history.pushState(null, null, document.URL);
    // 给window添加一个popstate事件，拦截返回键，执行this.onBrowserBack事件，addEventListener需要指向一个方法
        window.addEventListener("popstate", this.onBrowserBack, false);
    },
    beforeDestroy () {
        window.removeEventListener("popstate", this.onBrowserBack, false);
    },
    destroyed () {
        window.removeEventListener("popstate", this.onBrowserBack, false);
    },
    created () {
        var islogin= this.$cookie.get('breakthrouth_userinfo');
        if(islogin){
            this.token = JSON.parse(this.$cookie.get('breakthrouth_userinfo')).token
            this.getClassList()
        }
        if(GetArgsFromHref('source')){
            if(!this.$cookie.get('breakthrouth_source')){
                this.$cookie.set('breakthrouth_source',GetArgsFromHref('source'), { expires: 365 });
            }
        }
        this.activity_id=GetArgsFromHref('activity_id')||'';
        this.source=GetArgsFromHref('source')||this.$cookie.get('breakthrouth_source')||'1';
    },
    methods:{
        onBrowserBack () {
            window.location.reload()
        },
        async getClassList() {
            this.$toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
            let res = await activityList({
                token:this.token
            })
            this.$toast.clear()
            if (res.code === 0) {
                this.datainfo = res.data || {}
                sessionStorage.setItem('title',this.datainfo.title)
            }else if(res.error== 5001){
                this.$cookie.remove('breakthrouth_userinfo')
            } else{
                this.msg = res.msg
            }
        },
        //查看答案或报告
        lookbtn(type,activity_id){
            this.$router.push({  
                path:type=='answer'?'./answersheet':'./report',
                query:{
                    activity_id:activity_id,
                }
            })
        },
        //闯关
        handleNextPaper(activity_id,report_id){
            this.$router.push({
                path:'./answerquestion',
                query:{
                    report_id:report_id,
                    activity_id:activity_id,
                }
            })
        }    
    }
}
</script>
<style lang="scss" scoped>
.indexmain{
    padding: 1rem;
    position: relative;
    .indexicon{
        margin-top:4rem;
    }
    .customs_pass{
        color: #1C1C1C;
        font-size: 1.2rem;
        margin:.8rem 0 .6rem 0;
    }
    .customs_pass_tips{
        color: #A6A9AE;
        font-size: 1rem;
    }
    .cg_main{
        margin: 50px auto;
    }
    .end{
        padding-top: 5rem;
    }
    .btngroup{
        margin-top: 5rem;
    }
}

</style>
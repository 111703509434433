<template>
  <div class="height100vh loginmain">
    <h2>您好！</h2>
    <h5>欢迎您来闯关！</h5>
    <van-form  class="login-form" ref="loginForm">
      <h3>登录</h3>
      <van-field v-model="loginForm.name"
                name="username"
                placeholder="学生姓名"
               :rules="ruleUsername"
               @input="() => (loginForm.name = loginForm.name.trim())"
               >
      </van-field>
      <van-field v-model="loginForm.phone"
                name="phone"
                maxlength="11"
                placeholder="手机号码"
               :rules="ruleMobile"
               @input="() => (loginForm.phone = loginForm.phone.trim())"
               >
      </van-field>
      <van-field v-model="loginForm.code"
                     center
                     placeholder="验证码"
                     name="code"
                     :rules="ruleCode" 
                     @input="() => (loginForm.code = loginForm.code.trim())"
                     >
            <template #button>
              <div class="sendmsg">
                <span @click.stop="sendMsg"
                      v-if="!yamFlag"> {{yammsg}}</span>
                <span v-else
                      class="gray"> {{yammsg}}</span>
              </div>
            </template>
          </van-field>
      <div class="btngroup">
        <van-button block type="info" color="#4D78FF" :loading="isLoading" @click="onSubmit">登录</van-button>
      </div>
    </van-form> 
  </div>
</template>

<script>
import { encry, encryName } from "utils/util";
// 引入首页背景图片
import { login,sendCode } from "api";
export default {
  data() {
    return {
      isLoading: false,
      yamFlag:false,
      loginForm:{
        name: "",
        phone: "",
        code: '',
        source:'',
        activity_id:''
      },
      yammsg:'发送验证码',

      ruleUsername: [
        {
          required: true,
          message: "姓名不能为空",
          trigger: "onBlur",
        },
        { pattern: /^[\u4e00-\u9fa5a-zA-Z]{2,16}$/, message: "长度在2-16个字符" },
      ],
      ruleMobile: [
        {
          required: true,
          message: "手机号不能为空",
          trigger: "blur",
        },
        { pattern: /^[0-9]{6,16}$/, message: "长度在6-16个字符" },
      ],
      ruleCode:[
        {
          required: true,
          message: '验证码不能为空',
          trigger: 'onBlur'
        },
        {
          validator: value => {
            return /^[0-9]{4,6}$/.test(value)
          },
          message: '长度在4-6个字符',
          trigger: 'onBlur'
        },
      ]
    }
  },
  created(){
    this.loginForm.activity_id=this.$route.query.activity_id;
    this.loginForm.source=this.$route.query.source;  
  },
  methods: {
    //发送验证码
    sendMsg () {
      this.$refs.loginForm.validate('phone').then(() => {
        this.sendMsgfn()
      }).catch(() => {
        this.$toast('请填写正确的手机号');
      })
    },
    async sendMsgfn () {
      let res = await sendCode({phone: encry(this.loginForm.phone)})
      if (res.error == 0) {
        this.$toast.success(res.msg)
        this.timeCountDown();
      } else {
        this.$dialog.alert({
          title: '提示',
          message: res.msg,
        }).then(() => {
        });
      }
    },
        //验证码倒计时
    timeCountDown () {
      var time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          window.clearInterval(this.timer);
          this.yammsg = "发送验证码";
          this.yamFlag = false;
          return true;
        }
        this.yammsg = time + "S";
        time--;
        this.yamFlag = true;
        return false;
      }, 1000);
    },
    /**登录 */
    onSubmit() {
        this.$refs.loginForm.validate().then(async (valid)=>{
          this.isLoading = true;
          let res = await login({
            ...this.loginForm,
            name: encryName(this.loginForm.name),
            phone: encry(this.loginForm.phone)
          });
          this.isLoading = false;
          if (res.code == 0) {
            let obj = res.data || {};
            this.$cookie.set('breakthrouth_userinfo',JSON.stringify(obj), { expires: 365 });
            this.$cookie.set('breakthrouth_activity_id',obj.activity_id, { expires: 365 });
            this.$cookie.set('breakthrouth_source',this.loginForm.source, { expires: 365 });
            if(res.data.is_upload_detail==0){
              this.$router.push({
                path:"/stuInfo",
                query:{
                  activity_id:obj.activity_id,
                }
              });
            }else{
              this.$router.push({
                path:"/index",
                query:{
                  activity_id:obj.activity_id,
                }
              });
            }
          }
        }).catch(()=>{
          this.$toast('缺少必填信息！')
        });    
    },
  },
}
</script>

<style lang="scss" scoped>
.loginmain{
  background: url(../assets/imgs/bg.png) no-repeat;
  background-size: cover;
  padding:4rem 1rem 0;
  box-sizing: border-box;
  text-align: left;
  h2{
    color: #fff;
    font-size: 1.5rem;
  }
  h3{
    color: #1C1C1C;
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  h5{
    color: #fff;
    font-size: 1.1rem;
    margin: 1.1rem 0;
    font-weight: normal;
  }
  .login-form{
    background: #fff;
    border-radius: 10px;
    padding: 2rem 1.2rem;
    .sendmsg{
      width: 6rem;
      text-align: center;
      color: #4D78FF;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        left:0;
        top:50%;
        transform: translateY(-50%);
        height:15px;
        border-left: 1px solid #D0DCED;
      }
    }
  }
}
</style>
<style lang="scss">
.loginmain{
  .van-cell{
    background: #F5F7FA;
    border-radius: 50px;
    margin-bottom: 1.4rem;
    input::input-placeholder{
      color: #92959B;
    }
  }
}
</style>
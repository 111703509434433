<template>
    <div class="height100vh introduce">
        <div class="main">
            <h3>活动介绍（活动名称/简介）</h3>
            <div class="introduceinfo">可能是文字详情或者是图片详情区域</div>
            <div class="btngroup">
                <van-button block color="#4D78FF" type="primary" round @click="nextBtn">开始闯关</van-button>
            </div>
        </div>

    </div>
</template>
<script>
import Nav from 'comps/Nav'
export default {
    name:'activeIntroduce',
    components: {
        Nav,
    },
    data(){
        return {

        }
    },
    methods:{
        nextBtn(){
            this.$router.push('./index')
        }
    },
    created(){

    }
}
</script>
<style lang="scss" scoped>
.introduce{
  background: url(../assets/imgs/bg.png) no-repeat;
  background-size: cover;
  padding:2rem 1rem;
  box-sizing: border-box;
  .main{
    background: #fff;
    border-radius: 10px;
    padding: 2rem 1.2rem;
        h3{
            color: #1C1C1C;
            font-size: 1rem;
            text-align: center;
        }
        .introduceinfo{
            height: 60vh;
            line-height: 60vh;
        }
    }
}

</style>
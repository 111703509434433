import { XXTEA } from "../assets/js/xxtea.min.js";
import {ENCRY_KEY} from 'utils/config'

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}
export function encry(val) {
  if (typeof val === "object") {
    Object.keys(val).forEach(
      (v) => (val[v] = XXTEA.encryptToBase64(val[v], ENCRY_KEY))
    );
    return val;
  } else {
    return XXTEA.encryptToBase64(val, ENCRY_KEY);
  }
}
export function encryName(val) {
  return encry(encodeURIComponent(val))
}
// 截取url参数
export const GetArgsFromHref = (sArgName) => {
  var sHref = window.location.href;
  var args = sHref.split("#");
  var retval = "";
  if (args[0].indexOf('?') != -1) {
    var argstwo = args[0].split("?");
    if (argstwo[1] == "") {
      return retval;
    } else {
      var str = argstwo[1];
      args = str.split("&");
      for (var i = 0; i < args.length; i++) {
        str = args[i];
        var arg = str.split("=");
        if (arg.length <= 1) continue;
        if (arg[0] == sArgName) retval = arg[1];
      }
      return retval;
    }
  }
}
export function countTime(val) {
  var h = parseInt(val / 60 / 60 % 24); //时
  h = h < 10 ? '0' + h : h;
  var m = parseInt(val / 60 % 60); //分
  m = m < 10 ? '0' + m : m;
  var s = parseInt(val % 60); // 秒
  s = s < 10 ? '0' + s : s;
  return h + ':' + m + ':' + s;
}